import { format } from 'date-fns'
import { useCustomerWorkOrdersSummary } from '../../hooks/useCustomerWorkOrdersSummary'
import Badge, { BadgeColorType } from '../badge'
import Table, { ColumnsType } from '../table'

interface DataType {
  key: string;
  patient: string | null;
  description: string;
  status: string | null;
}

const STATUS_COLORS: Record<string, BadgeColorType> = {
  'en espera': 'light',
  'en proceso': 'warning',
  finalizado: 'success'
}

const columns: ColumnsType<DataType> = [
  {
    title: 'ID',
    dataIndex: 'key',
    key: 'key',
    render: (text) => (
      <strong>
        #
        {text.padStart(6, '0')}
      </strong>
    )
  },
  {
    title: 'Paciente',
    dataIndex: 'patient',
    key: 'patient',
    render: (text) => text
  },
  {
    title: 'Descripción',
    dataIndex: 'description',
    key: 'description',
    render: (text) => {
      const textArray = text.split('\n').map((string) => string.split('_:'))
      let textString = ''

      if (text.includes('_:')) {
        for (let i = 0; i < textArray.length; i += 1) {
          textString += `<div><strong>${textArray[i][0]}:</strong> ${textArray[i][1]?.trim()}</div>`
        }

        return <div dangerouslySetInnerHTML={{ __html: textString }} />
      }
      return <div className="whitespace-pre-line">{text}</div>
    }
  },
  {
    title: 'Creación',
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (text) => text
  },
  {
    title: 'Entrega estimada',
    key: 'estimatedAt',
    dataIndex: 'estimatedAt',
    render: (text) => text
  },
  {
    title: 'Estado',
    key: 'status',
    dataIndex: 'status',
    render: (text) => <Badge label={text.toUpperCase()} color={STATUS_COLORS[text]} />
  }
]

export function CustomerWorkOrdersSummaryTable () {
  const { workOrdersSummary, isFetching } = useCustomerWorkOrdersSummary()

  const dataSource = workOrdersSummary?.data.map((workOrder) => ({
    key: workOrder.id.toString(),
    patient: workOrder.patient.name,
    description: workOrder.description || '',
    status: workOrder.state,
    createdAt: workOrder.created_at ? format(new Date(workOrder.created_at), 'dd/MM/yyyy') : '',
    estimatedAt: workOrder.estimated_delivery_date ? format(new Date(workOrder.estimated_delivery_date), 'dd/MM/yyyy') : ''
  })) || []

  return (
    <>
      <h2 className="text-center mt-8 text-lg font-semibold">{!columns ? 'Trabajos en proceso...' : 'No hay trabajos en proceso...'}</h2>
      <Table dataSource={dataSource} columns={columns} bordered isFetching={isFetching} />
    </>
  )
}
