import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
  BrowserRouter,
  useRoutes
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Analytics } from '@vercel/analytics/react'

import routes from './router'

const queryClient = new QueryClient()

export function App () {
  const content = useRoutes(routes)
  return content
}

export function WrappedApp () {
  if (import.meta.env.VITE_USE_SENTRY === 'true') {
    Sentry.init({
      dsn: 'https://e909ce1a6d264a7abe583ca7ebccabaf@o4505590441508864.ingest.sentry.io/4505590443147264',
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost', import.meta.env.VITE_BASE_URL || '']
        }),
        new Sentry.Replay()
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }

  document.title = import.meta.env.VITE_APP_TITLE || 'Laboratorio'

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
        <Analytics/>
      </QueryClientProvider>
    </BrowserRouter>
  )
}
