import { useEffect } from 'react'
import useStore from '../store'
import { ToastMessageType } from '../store/slices/ToastSlice'
import CheckIcon from './icons/CheckIcon'
import ErrorIcon from './icons/ErrorIcon'
import WarningIcon from './icons/WarningIcon'

const ToastIconByType: Record<ToastMessageType, JSX.Element> = {
  success: <CheckIcon />,
  error: <ErrorIcon />,
  warning: <WarningIcon />
}

function Toast () {
  const toastMessages = useStore((state) => state.messages)
  const removeToastMessage = useStore((state) => state.removeMessage)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (toastMessages.length) {
        removeToastMessage(toastMessages[0].id)
      }
    }, 5000)

    return () => clearTimeout(timeout)
  }, [toastMessages, removeToastMessage])

  return (
    <div className="absolute top-5 left-[50%] transform translate-x-[-50%] transition ease-in delay-150 z-[60]">
      {
        toastMessages.length
          ? toastMessages.map((message) => (
          <div key={message.id} id={`toast-${message.type}`} className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
            {ToastIconByType[message.type]}
            <div className="ml-3 text-sm font-normal">{message.text}</div>
          </div>
          ))
          : null
      }
    </div>
  )
}

export default Toast
