import Spinner from './spinner'

type Fill = 'solid' | 'outline' | 'text';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  fill?: Fill;
  isLoading?: boolean;
}

const buttonStyle = (fill: Fill, className: string) => {
  switch (fill) {
    case 'solid':
      return `inline-flex items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-dark-900 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition-colors duration-300 ${className}`

    case 'outline':
      return `inline-flex items-center justify-center rounded-md border border-primary bg-white px-4 py-2 text-sm font-medium text-primary shadow-sm hover:bg-rose-100 focus:outline-none transition-colors duration-300 ${className}`

    case 'text':
      return `inline-flex items-center justify-center rounded-md border border-transparent bg-transparent px-4 py-2 text-sm font-medium text-primary shadow-sm hover:bg-rose-100 focus:outline-none transition-colors duration-300 ${className}`

    default:
      return className
  }
}

function Button ({
  label,
  children,
  fill = 'solid',
  isLoading = false,
  className = '',
  ...props
}: ButtonProps) {
  return (
    <button
      type="button"
      {...props}
      className={buttonStyle(fill, className)}
    >
      {isLoading && (
        <Spinner />
      )}
      {
        isLoading ? 'Procesando...' : label || children
      }
    </button>
  )
}

export default Button
