function CydenIcon (props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={151}
    height={159} viewBox="0 0 181 206" fill="none" xmlns="http://www.w3.org/2000/svg"
    {...props}>
      <rect x="27" y="100.029" width="116.867" height="42.9962" transform="rotate(-43.2184 27 100.029)" fill="#649E8D"/>
      <rect x="58.6173" y="70.7168" width="116.867" height="42.9962" transform="rotate(46.9387 58.6173 70.7168)" fill="#FA775B"/>
      <rect x="27.1918" y="100.161" width="42.7632" height="42.9327" transform="rotate(-43.5152 27.1918 100.161)" fill="#8F6FA9"/>
    </svg>
  )
}

export default CydenIcon
